import React from "react";
import VeroImg from '../../assets/imgs/nutriologa-veronica-barrios.png'

const Hero = () => {
  return (
    <section className="bg-emerald-200 py-16 px-4 mt-[90px]">
      <div className="container mx-auto flex flex-col md:flex-row items-center min-h-[330px]">
        <div className="">
          <h1 className="text-4xl md:text-6xl font-bold text-center">
            Soy Verónica, tu nutrióloga de confianza.
          </h1>
          <p className="text-center text-xl text-gray-600 mt-6">
            Te proporciono una consulta personalizada, utilizando mi
            experiencia para ayudarte a alcanzar tus objetivos de salud y
            bienestar.
          </p>
          <div className="mt-10 text-center">
            <a href="/contact"
              className="bg-teal-800 text-white text-2xl rounded-full shadow-lg py-4 px-8">
              ¡Quiero una consulta!
            </a>
          </div>
        </div>
        <div className="hidden sm:block">
          <img
            src={VeroImg}
            alt="Nutria. Verónica Barrios"
            className="w-80 h-48 md:w-72 md:h-72 object-cover mt-8"
          />
        </div>
      </div>
    </section>
  );
}

export default Hero;