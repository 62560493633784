import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const ReservationForm = () => {
  const [formData, setFormData] = useState({
    date_reservation: '',
    start_time: '',
    end_time: '',
    title: 'Quiero una Consulta nutricional 😎🍛🥪🥗🥑🥦🥤✅',
    price: '$350.00 MXN',
    profesional_name: '🤦 Verónica Barrios A.',
    status: 'reserved'
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://dev.nutriologaverobarrios.com/reservations', {
        reservation: formData
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('PRINT' + response.data);
      Swal.fire({
        title: '¡Muy bien!',
        text: 'Tu reservación fue creada.',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700'
        }
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error('Error making reservation:', error);
      Swal.fire({
        title: '¡Ops!',
        text: 'Hubo un error en tu reservación. Por favor intenta nuevamente.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700'
        }
      });
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit} className="p-4 bg-white shadow-md rounded-lg">
        <div className="mb-4">
          <label htmlFor="title" className="block text-gray-500 font-medium">Asunto</label>
          <h4 className='border border-gray-200 p-2 rounded bg-gray-100 text-gray-500'>{formData.title}</h4>
        </div>
        <div className="mb-4">
          <label htmlFor="profesional_name" className="block text-gray-500 font-medium">Nutrióloga</label>
          <h4 className='border border-gray-200 p-2 rounded bg-gray-100 text-gray-500'>{formData.profesional_name}</h4>
        </div>
        <div className="mb-4">
          <label htmlFor="date_reservation" className="block text-gray-500 font-medium">Fecha</label>
          <input
            type="date"
            name="date_reservation"
            value={formData.date_reservation}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            placeholder="Fecha"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="start_time" className="block text-gray-500 font-medium">Hora de inicio</label>
          <input
            type="time"
            name="start_time"
            value={formData.start_time}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="end_time" className="block text-gray-500 font-medium">Hora de finalización</label>
          <input
            type="time"
            name="end_time"
            value={formData.end_time}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="price" className="block text-gray-500 font-medium">Precio</label>
          <h4>{formData.price}</h4>
        </div>
        <div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-full hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReservationForm;
