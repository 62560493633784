import React from 'react';
import ReservationForm from '../components/ReservationForm';
import ContactInfo from '../components/ContactInfo';
import ContactFaq from '../components/ContactFaq';
import ContactTestimonials from '../components/ContactTestimonials';
import SocialNetworks from '../components/SocialNetworks';


const Contact = () => {
  return (
    <div className="container mx-auto bg-gradient-to-b from-teal-100 via-teal-500 to-blue-500 mt-24">
      <div className="grid grid-cols-1 p-4">
        <h2 className="font-roboto text-4xl font-bold text-center mt-4 mb-4">¡Estoy aquí para ayudarte!</h2>
        <p className='text-lg text-gray-600 font-montserrat font-medium text-center max-w-96 mx-auto mb-9'>"Agenda tu cita para resolver tus dudas. Me comprometo a guiarte hacia tu mejor versión."</p>

        {/* Component for form  */}
        <ReservationForm />

        {/* Component */}
        <ContactInfo />

        {/* <!-- FAQ Section --> */}
        <ContactFaq />

        {/* <!-- Testimonials Section --> */}
        <ContactTestimonials />

        {/* <!-- Social Media Section --> */}
        <SocialNetworks />
      </div>
    </div>
  );
};

export default Contact;