import React from "react";
import { ReactComponent as FacebookIcon } from '../assets/imgs/facebook.svg';
import { ReactComponent as InstragramIcon } from '../assets/imgs/instagram.svg';

const SocialNetworks = () => {
  return (
    <section className="container mx-auto flex flex-col justify-center items-center p-6 bg-white mt-6 shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">Encuéntranos en las Redes</h2>
      <div className="flex space-x-4">
        <a href='https://www.facebook.com/profile.php?id=61558143525876' rel="noreferrer" target='_blank' className="text-red-700">
          <FacebookIcon className="w-8 h-8 text-red-500" />
        </a>
        <a href='/instragram' className="">
          <InstragramIcon className="w-8 h-8 text-red-500" />
        </a>
      </div>
    </section>
  );
}

export default SocialNetworks;