/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

const ContactInfo = () => {
  return (
    <div className='grid md:grid-cols-2 bg-white mt-6 rounded-lg shadow-md'>
      {/* <!-- Contact Information Section --> */}
      <section className="container mx-auto p-6">
        <h2 className="text-xl font-bold mb-4">Información de Contacto</h2>
        <p><strong>Teléfono:</strong> <a href="tel: +524771161162">+52 477 116 1162</a></p>
        <p><strong>Correo Electrónico:</strong> <a href='mailto:hola@nutriologaverobarrios.com?subject=Consulta%20Nutricional&body=Hola%20Nutriologa%20Vero,%0D%0A%0D%0AQuisiera%20solicitar%20una%20consulta%20para%20hablar%20sobre%20mi%20dieta%20y%20nutrición.%0D%0A%0D%0AGracias,%0D%0A[TuNombre]'>hola@nutriologaverobarrios.com</a></p>
        <p><strong>Domicilio:</strong>
          <a href='https://maps.app.goo.gl/z46zKeT8UrmavYFv5' className='text-sky-700' target='_blank' rel='noopener noreferrer'> Blvd. Juan Alonso de Torres Pte. 2900, Unión Comunitaria de Leon, 37239 León de los Aldama, Gto.</a></p>

        <h2 className="text-xl font-bold mb-4 mt-8">Horarios de Atención</h2>
        <p><strong>Lunes a Viernes:</strong> 10:00 AM - 6:00 PM</p>
        <p><strong>Sábado:</strong> 10:00 AM - 2:00 PM</p>
        <p><strong>Domingo:</strong> Cerrado</p>
      </section>

      {/* <!-- Map Section --> */}
      <section className="container mx-auto p-6">
        <h2 className="text-xl font-bold mb-4">Ubicación</h2>
        <div className="w-full h-96">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.1973200591183!2d-101.64860552395716!3d21.144544283790257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bbec24462daef%3A0x335e94266b789737!2sBlvd.%20Juan%20Alonso%20de%20Torres%20Pte.%202900%2C%20Uni%C3%B3n%20Comunitaria%20de%20Leon%2C%2037239%20Le%C3%B3n%20de%20los%20Aldama%2C%20Gto.!5e0!3m2!1ses-419!2smx!4v1719283652450!5m2!1ses-419!2smx" width="100%" height="100%" loading="lazy" />
        </div>
      </section>
    </div >
  );
}

export default ContactInfo;