import React from "react"

const Footer = () => {
  return (
    <footer class="bg-cyan-950 text-white py-8 px-4 mt-8">
      <div class="container mx-auto flex flex-col md:flex-row justify-between">
        {/* <div class="space-y-4 text-center md:text-left">
          <h4 class="text-xl font-bold">Logoipsum</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <div class="flex justify-center md:justify-start space-x-4">
            <a href="/facebook" class="text-white"><i class="fab fa-facebook-f"></i></a>
            <a href="/twitter" class="text-white"><i class="fab fa-twitter"></i></a>
            <a href="instagram" class="text-white"><i class="fab fa-instagram"></i></a>
          </div>
        </div> */}
        <div class="space-y-4 text-center md:text-left mt-8 md:mt-0">
          <h4 class="text-xl font-bold">Contáctame</h4>
          <p><strong>Domicilio / Ubicación:</strong>
            <a href='https://maps.app.goo.gl/z46zKeT8UrmavYFv5' className='text-sky-200' target='_blank' rel='noopener noreferrer'> Blvd. Juan Alonso de Torres Pte. 2900, Unión Comunitaria de Leon, 37239 León de los Aldama, Gto.</a></p>
          <p><strong>Teléfono:</strong> <a href="tel: +524771161162" className='text-sky-200'>+52 477 116 1162</a></p>
          <p><strong>Correo Electrónico:</strong> <a href='mailto:hola@nutriologaverobarrios.com?subject=Consulta%20Nutricional&body=Hola%20Nutriologa%20Vero,%0D%0A%0D%0AQuisiera%20solicitar%20una%20consulta%20para%20hablar%20sobre%20mi%20dieta%20y%20nutrición.%0D%0A%0D%0AGracias,%0D%0A[TuNombre]' className='text-sky-200'>hola@nutriologaverobarrios.com</a></p>
        </div>
        {/* <div class="space-y-4 text-center md:text-left mt-8 md:mt-0">
          <h4 class="text-xl font-bold">Links</h4>
          <a href="/privacy-policy" class="text-white">Privacy Policy</a>
          <a href="/terms-of-service" class="text-white">Terms of Service</a>
          <a href="/returns" class="text-white">Returns & Exchanges</a>
        </div> */}
      </div>
      <div class="mt-8 text-center">
        <p>&copy; 2024 Nutrióloga Verónica Barrios. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
}

export default Footer;