import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { formatDate } from '../utils/Utils';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Define el token (en una aplicación real, lo obtendrías de forma segura)
  // const token = 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyfQ.1LOvGy6YD17jhvxhcG4vlPi26rN96fdYS8XZXObFPuk';
  const token = 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyfQ.0djiHE6NRw08bXojv6shMgF_FhoUYbeBedLRLLaMBbE';

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        //https://api-auth-app-ebcd756fdf21.herokuapp.com
        const response = await axios.get('http://192.168.100.15:3001/users', {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setUsers(response.data);
      } catch (error) {
        setError('Error fetching users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <div className="container mx-auto p-4">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-4">{error}</div>;
  }

  return (
    <div className="container mx-auto mt-20 p-4 lg:max-w-screen-lg xl:max-w-screen-xl mh-3">
      <h1 className="text-4xl font-bold">Users</h1>
      <ul className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4'>
        {users.map(user => (
          <li key={user.id} className="py-10 border border-gray-200 rounded-md flex flex-col items-center" >
            <img
              className="w-24 h-24 border-slate-400 border-4 rounded-full mb-3"
              src="https://picsum.photos/400"
              alt="Default avatar"
            />
            <p className=' text-blue-900 text-md font-semibold'>{user.email}</p>
            <span className='text-gray-400 text-sm'>Joined in {formatDate(user.created_at)}</span>
          </li>
        ))}
      </ul>
    </div >
  );
};

export default Users;
