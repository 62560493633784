import React from "react";
import DietaImg from '../../assets/imgs/preparando-comida-hombre.jpg'

const InnovativeDiet = () => {
  return (
    <section className="bg-white py-16 px-4">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2">
          <img
            src={DietaImg}
            alt="Dieta fácil creativa e innovaroda"
            className="rounded-lg w-full"
          />
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0 md:pl-12">
          <h3 className="text-4xl font-bold font-dm-sans">
            Mejora tu salud de manera sencilla a través de tu alimentación.
          </h3>
          <p className="text-gray-600 mt-4 text-xl">
            Explora un método innovador que cambia la forma en que gestionas tu
            alimentación, convirtiéndola en una experiencia creativa y
            personalizada. En nuestra consulta, descubrirás herramientas
            prácticas y consejos especializados diseñados para adaptarse
            perfectamente a tu estilo de vida, facilitando así el camino hacia
            tus metas de salud y bienestar.
          </p>
          <p className="text-gray-600 mt-4 text-xl">
            Nuestro objetivo es guiarte hacia hábitos alimenticios que no solo
            mejoren tu salud física, sino que también promuevan tu bienestar
            emocional y mental.
          </p>
          {/* <button className="bg-yellow-500 text-white mt-6 px-6 py-4 rounded-tl-2xl rounded-br-2xl shadow-md">
            Conocer más...
          </button> */}
        </div>
      </div>
    </section>
  );
}

export default InnovativeDiet;