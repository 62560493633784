import React, { useEffect } from "react";

const ContactFaq = () => {

  useEffect(() => {
    // Seleccionar todos los botones de pregunta
    const buttons = document.querySelectorAll('.faq-question');

    // Iterar sobre cada botón y agregar el evento click
    buttons.forEach((button, index) => {
      button.addEventListener('click', () => {
        const answer = button.nextElementSibling;
        const icon = button.querySelector('svg');

        // Toggle the hidden class to show/hide the answer
        answer.classList.toggle('hidden');

        // Rotate the icon
        icon.classList.toggle('rotate-180');
      });

      // Abrir automáticamente el primer elemento
      if (index === 0) {
        const firstAnswer = button.nextElementSibling;
        firstAnswer.classList.remove('hidden'); // Mostrar la primera respuesta
      }
    });

    // Limpiar el evento al desmontar el componente
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('click', () => { });
      });
    };
  }, []);


  return (
    <section className="container mx-auto p-6 bg-white mt-6 shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4 text-center">Preguntas comunes</h2>

      <div className="faq-item border-b py-4">
        <button className="faq-question text-left w-full flex justify-between items-center font-semibold text-gray-700">
          <span>¿Qué servicios ofrece el consultorio de nutrición?</span>
          <svg className="w-5 h-5 transform transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>
        <div className="faq-answer hidden mt-2 text-gray-600">
          <p>En nuestro consultorio ofrecemos una variedad de servicios personalizados de nutrición, que incluyen evaluaciones nutricionales completas, planes de alimentación personalizados, educación sobre nutrición y estilo de vida, seguimiento continuo y asesoramiento para alcanzar tus metas de salud y bienestar.</p>
        </div>
      </div>

      <div className="faq-item border-b py-4">
        <button className="faq-question text-left w-full flex justify-between items-center font-semibold text-gray-700">
          <span>¿Cómo puedo programar una cita?</span>
          <svg className="w-5 h-5 transform transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>
        <div className="faq-answer hidden mt-2 text-gray-600">
          <p>Puedes programar una cita llamando a nuestra recepción al 477 116 1162 o mediante nuestro sitio web en la sección de citas. También puedes enviarnos un correo electrónico a hola@nutriologaverobarrios.com y nuestro equipo se pondrá en contacto contigo para confirmar la cita.</p>
        </div>
      </div>

      <div className="faq-item border-b py-4">
        <button className="faq-question text-left w-full flex justify-between items-center font-semibold text-gray-700">
          <span>¿Cuál es mi formación y experiencia?</span>
          <svg className="w-5 h-5 transform transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>
        <div className="faq-answer hidden mt-2 text-gray-600">
          <p>Nuestros nutricionistas están altamente capacitados y certificados en nutrición y dietética. Cada uno posee una licenciatura en nutrición, y algunos tienen especializaciones adicionales en áreas como nutrición deportiva, salud digestiva, y manejo de enfermedades crónicas. Todos nuestros profesionales están comprometidos con la actualización constante y siguen las últimas investigaciones en el campo de la nutrición.</p>
        </div>
      </div>

      <div className="faq-item border-b py-4">
        <button className="faq-question text-left w-full flex justify-between items-center font-semibold text-gray-700">
          <span>¿Cómo se realiza una consulta inicial y qué debo llevar preparado?</span>
          <svg className="w-5 h-5 transform transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>
        <div className="faq-answer hidden mt-2 text-gray-600">
          <p>Durante la consulta inicial, realizamos una evaluación detallada de tu historial médico, hábitos alimenticios actuales, objetivos de salud y cualquier preocupación específica que tengas. Es útil que traigas contigo registros de comidas y bebidas recientes, así como cualquier medicamento o suplemento que estés tomando.</p>
        </div>
      </div>

      <div className="faq-item border-b py-4">
        <button className="faq-question text-left w-full flex justify-between items-center font-semibold text-gray-700">
          <span>¿Qué tipos de dietas o programas de alimentación personalizados ofrecen?</span>
          <svg className="w-5 h-5 transform transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>
        <div className="faq-answer hidden mt-2 text-gray-600">
          <p>Creamos planes de alimentación personalizados adaptados a tus necesidades individuales y objetivos de salud. Esto puede incluir dietas para pérdida de peso, control de enfermedades como la diabetes o la hipertensión, mejoría en el rendimiento deportivo, manejo de alergias alimentarias, entre otros.</p>
        </div>
      </div>

      <div className="faq-item border-b py-4">
        <button className="faq-question text-left w-full flex justify-between items-center font-semibold text-gray-700">
          <span>¿Cuántas consultas suelen ser necesarias para alcanzar mis objetivos nutricionales?</span>
          <svg className="w-5 h-5 transform transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>
        <div className="faq-answer hidden mt-2 text-gray-600">
          <p>El número de consultas necesarias varía según cada individuo y sus metas específicas. Por lo general, recomendamos un programa inicial que incluye una consulta de evaluación y seguimiento regular para ajustar el plan de alimentación según sea necesario. Esto asegura un progreso continuo hacia tus objetivos nutricionales.</p>
        </div>
      </div>

      <div className="faq-item border-b py-4">
        <button className="faq-question text-left w-full flex justify-between items-center font-semibold text-gray-700">
          <span>¿Qué coste tienen las consultas y los programas de alimentación?</span>
          <svg className="w-5 h-5 transform transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>
        <div className="faq-answer hidden mt-2 text-gray-600">
          <p>Nuestros costes varían dependiendo del tipo de servicio y la duración de las consultas. Ofrecemos varias opciones y paquetes de consultas que se ajustan a diferentes presupuestos y necesidades. Para obtener detalles específicos sobre precios, te recomendamos contactarnos directamente para discutir tus requerimientos.</p>
        </div>
      </div>

    </section>
  );
}

export default ContactFaq;