import React from 'react';

import Hero from '../components/Home/Hero';
import Resources from '../components/Home/Resources';
import Authority from '../components/Home/Authority';
import Benefits from '../components/Home/Benefits';
import Joinus from '../components/Home/Joinus';
import InnovativeDiet from '../components/Home/InnovativeDiet';

const Home = () => {
  return (
    <div classNameName="">
      {/* Hero section */}
      <Hero />

      {/* About section */}
      <InnovativeDiet />

      {/* Resources Section */}
      {/* <Resources /> */}

      {/* Authority Section */}
      {/* <Authority /> */}

      {/* Benefits section */}
      <Benefits />

      {/* Joinus Section  */}
      {/* <Joinus /> */}
    </div>
  );
};

export default Home;