import { GiHealing } from "react-icons/gi";
import { BiHomeHeart } from "react-icons/bi";
import { CgMenuHotdog } from "react-icons/cg";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <div id="mobile-menu">
      <button
        id="mobile-menu-button"
        onClick={handleMenuToggle}
        className="text-gray-800 p-2 w-full py-4"
      >
        <CgMenuHotdog className="w-8 h-8" />
      </button>
      <div id="mobile-menu-content" className={`absolute top-20 left-0 right-0 bg-white text-gray-800 border w-full shadow-md ${isOpen ? 'block' : 'hidden'} transition-all duration-300 transform origin-top ${isOpen ? 'translate-y-0' : '-translate-y-full'}`}>
        <Link to="/" className="flex items-center px-4 py-2 text-xl font-medium border-b border-green-150" onClick={handleLinkClick}>
          <BiHomeHeart className="mr-2 w-8 h-8" />
          <span>Inicio</span>
        </Link>
        {/* <Link to="/about" className="flex items-center  px-4 py-2 text-xl font-medium" onClick={handleLinkClick}>
          <BiHomeHeart className="mr-2 w-6 h-6" />
          <span>Acerca</span>
        </Link>
        <Link to="/blog" className="flex items-center  px-4 py-2 text-xl font-medium" onClick={handleLinkClick}>
          <BiHomeHeart className="mr-2 w-6 h-6" />
          <span>Blog</span>
        </Link> */}
        <Link to="/contact" className="flex items-center  px-4 py-2 text-xl font-medium" onClick={handleLinkClick}>
          <GiHealing className="mr-2 w-8 h-8" />
          <span>Contacto</span>
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
