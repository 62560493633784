import React from "react";
import Logo from "../../assets/imgs/logo-nutriologa-veronica-barrios.png";
import { Link } from "react-router-dom";
import MobileMenu from './MobileMenu';


const Header = () => {
  return (
    <header className="bg-white py-2 fixed top-0 w-full z-50 shadow-md">
      <div className="container mx-auto flex justify-between items-center px-4">
        <div className="flex items-center">
          <img
            src={Logo}
            alt="logo nutriologa veronica barrios"
            className="w-10 h-10"
          />
          <p className="text-2xl font-bold ml-3">
            Nutrióloga Vero <span className="hidden sm:inline">Barrios</span>
          </p>
        </div>
        <nav className="hidden lg:flex space-x-6 items-center">
          <Link to="/" className="px-2 py-1 hover:bg-gray-200 rounded-md">Inicio</Link>
          <Link to="/about" className="px-2 py-1 hover:bg-gray-200 rounded-md">Acerca</Link>
          <Link to="/blog" className="px-2 py-1 hover:bg-gray-200 rounded-md">Blog</Link>
          <Link to="/contact" className="px-2 py-1 hover:bg-gray-200 rounded-md">Contacto</Link>
        </nav>
        <button className="bg-yellow-500 text-white px-4 py-2 rounded-md hidden lg:block">
          Solicitar cita
        </button>
        <div className="lg:hidden">
          <MobileMenu />
        </div>
      </div>
    </header>
  );
}

export default Header;