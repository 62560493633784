import React from "react";

const ContactTestimonials = () => {
  return (
    <section className="container mx-auto bg-white mt-6 lg:max-w-screen-xl p-6 shadow-md rounded-lg">
      <h2 className="text-xl font-bold text-center my-6">Vidas Cambiadas</h2>
      <div className='grid lg:grid-cols-3 gap-3 '>
        <div className='rounded border p-5 mt-3'>
          <p className='font-bold text-lg my-2'>¡He bajado 10 kilos en 3 meses!</p>
          <p className='text-slate-500'>"Gracias al plan personalizado y el apoyo constante del consultorio. ¡Me siento increíble!"</p>
          <div className='flex items-center mt-4'>
            <img
              className="w-10 h-10 rounded-full mr-2"
              src="https://picsum.photos/400"
              alt="Default avatar"
            />
            <p className='text-md font-bold font-sans flex flex-col'>Joana
              <span className='text-slate-500 text-sm font-normal'>Abril 2024</span>
            </p>
          </div>
        </div>
        <div className='rounded border p-5 mt-3'>
          <p className='font-bold text-lg my-2'>¡Mi energía ha incrementado!</p>
          <p className='text-slate-500'>"Mis problemas digestivos han desaparecido desde que empecé mi plan nutricional. ¡Muy agradecida!"</p>
          <div className='flex items-center mt-4'>
            <img
              className="w-10 h-10 rounded-full mr-2"
              src="https://picsum.photos/400"
              alt="Default avatar"
            />
            <p className='text-md font-bold font-sans flex flex-col'>María
              <span className='text-slate-500 text-sm font-normal'>Mayo 2024</span>
            </p>
          </div>
        </div>
        <div className='rounded border p-5 mt-3'>
          <p className='font-bold text-lg my-2'>¡Recomendado 100%!</p>
          <p className='text-slate-500'>"Gracias a las sesiones de nutrición, he aprendido a comer de manera balanceada y saludable. "</p>
          <div className='flex items-center mt-4'>
            <img
              className="w-10 h-10 rounded-full mr-2"
              src="https://picsum.photos/400"
              alt="Default avatar"
            />
            <p className='text-md font-bold font-sans flex flex-col'>Luis Martínez
              <span className='text-slate-500 text-sm font-normal'>Abril 2024</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactTestimonials;